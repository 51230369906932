import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={650}
    height={650}
    viewBox="0 0 650 650"
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#181B31" />
        <stop offset={0.25} stopColor="#3C3653" />
        <stop offset={0.5} stopColor="#655375" />
        <stop offset={0.75} stopColor="#927298" />
        <stop offset={1} stopColor="#F5B3D9" />
      </linearGradient>
      <clipPath id="a">
        <path
          data-name="Rectangle 154"
          fill="#fff"
          d="M1837 16108h650v650h-650z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 22"
      transform="translate(-1837 -16108)"
      clipPath="url(#a)"
    >
      <path
        data-name="Polygon 21"
        d="M433.232 0a120 120 0 0 1 103.913 59.982l98.19 170a120 120 0 0 1 0 120.037l-98.19 170A120 120 0 0 1 433.232 580H236.768a120 120 0 0 1-103.912-59.982l-98.19-170a120 120 0 0 1 0-120.037l98.19-170A120 120 0 0 1 236.768 0Z"
        transform="rotate(14.98 -60026.79 15266.51)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit={10}
        fill="url(#b)"
      />
    </g>
  </svg>
);
export default SVGComponent;

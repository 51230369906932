import React from 'react'
import ContactUs from '../landing/ContactUs'

function Popup({ open, OnClose, email = "", children }) {
    return (
        <div className={`relative z-50 ${open ? "" : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className={`flex items-center min-h-full justify-center p-4 text-center sm:items-center sm:p-0 ease-out duration-300 ${open ? "opacity-100 translate-y-0 sm:scale-100" : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}`}>
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl ">
                        <div className="absolute top-0 right-0 pt-4 pr-4  block z-50 rounded-full">
                            <button onClick={() => OnClose(false)} type="button" className="rounded-full bg-gray-300 border-2 border-white shadow-2xl shadow-gray-500/50  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span className="sr-only">Close</span>
                                <svg className="h-6 w-6 p-[1px]" xmlns="http://www.w3.org/2000/svg" fill={"white"} viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            {children ? children : <ContactUs email={email} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popup
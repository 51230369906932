import React from 'react'
import { Form, Formik } from "formik"
import * as Yup from 'yup';
import createContact from '../../Api/NewLetter';

const ContactSchema = Yup.object().shape({
    email: Yup.string().email('Enter the valid email').required('Email is required'),
});

function NewLetterForm({ email = '' }) {
    return (
        <div>
            <Formik
                initialValues={{
                    email: email ? email : '',
                }}
                enableReinitialize={true}
                validationSchema={ContactSchema}
                onSubmit={(values, { resetForm, setStatus, setSubmitting }) => {
                    setSubmitting(true)
                    try {
                        createContact(values);
                        setStatus({ message: 'Successfully send message', status: 200 })
                        setSubmitting(false)
                        setTimeout(() => {
                            resetForm()
                        }, 3000);
                    } catch (error) {
                        setStatus({ message: 'Something want wrong', status: 400 })
                        setSubmitting(false)
                    }
                }}
            >
                {({ handleBlur, handleChange, errors, touched, values, status, isSubmitting }) => (
                    <Form className={`mx-auto max-w-xl`}>
                        {
                            status &&
                            <div className="text-center py-4 lg:px-4">
                                <div className={`p-2  ${status.status !== 200 ? 'bg-red-400' : 'bg-lime-400 '} items-center text-white leading-none lg:rounded-full flex lg:inline-flex`} role="alert">
                                    <span className="font-semibold mr-2 text-left flex-auto">{status.message}</span>
                                </div>
                            </div>
                        }
                        <div className="mt-6 flex flex-wrap justify-end max-w-md gap-x-4">
                            <div className='flex flex-col w-full'>
                                <input id="email-address" name="email" type="email" value={values.email} onChange={handleChange} onBlur={handleBlur} className="min-w-0 flex-auto rounded-md border-[0.5px]  px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
                                {errors.email && touched.email ? <div className="text-red-400   text-sm py-1">{errors.email}</div> : false}
                            </div>
                            <button type='submit' disabled={isSubmitting} className="rounded-md px-3.5 py-2 m-1 overflow-hidden relative group cursor-pointer border-2 border-gray-200 font-medium text-primary">
                                <span className="absolute w-60 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-gray-200 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                <span className="relative text-primary/60 transition duration-300 group-hover:text-primary ease">Subscribe Now</span>
                            </button>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default NewLetterForm
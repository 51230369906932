
import { Form, Formik } from "formik"
import * as Yup from 'yup';
import createContact from "../../Api/NewLetter";

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Enter the valid email').required('Email is required'),
    phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter the valid phone number').required('Phone number is required'),
    message: Yup.string().min(3, "Too Short!").max(512, 'Too Long!')
});

function ContactUs({ email = "", hide = false }) {
    return (
        <div className="py-5 px-6 g:px-8">
            {!hide && <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Let&apos;s get in Touch</h2>
                {/* <p className="mt-2 text-lg leading-8 text-gray-600">
                    Aute magna irure deserunt veniam aliqua magna enim voluptate.
                </p> */}
            </div>}
            <Formik
                initialValues={{
                    name: '',
                    email: email ? email : '',
                    phone: '',
                    countryCode: '',
                    message: ""
                }}
                enableReinitialize={true}
                validationSchema={ContactSchema}
                onSubmit={(values, { setStatus, resetForm, setSubmitting }) => {
                    try {
                        setSubmitting(true)
                        createContact(values);
                        setStatus({ message: 'Successfully send message', status: 200 })
                        setSubmitting(false)
                    } catch (error) {
                        setStatus({ message: 'Something want wrong.', status: 500 })
                        setSubmitting(false)
                    }
                    setTimeout(() => {
                        resetForm();
                    }, 10000);
                }}
            >

                {({ handleBlur, handleChange, errors, touched, values, status, isSubmitting }) => (
                    <Form className={`mx-auto max-w-xl ${!hide ? 'mt-5' : ''}`}>
                        {
                            status &&
                            <div className="text-center py-4 lg:px-4">
                                <div className={`p-2  ${status.status !== 200 ? 'bg-red-400' : 'bg-lime-400 '} items-center text-white leading-none lg:rounded-full flex lg:inline-flex`} role="alert">
                                    <span className="font-semibold mr-2 text-left flex-auto">{status.message}</span>
                                </div>
                            </div>
                        }
                        <div className="grid grid-cols-1 gap-y-2 gap-x-8 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <label htmlFor="last-name" className="hidden text-sm font-semibold leading-6 text-gray-900">
                                    Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter your Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={`block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 sm:text-sm sm:leading-6 ${Boolean(errors.name) && touched.name ? 'ring-red-400' : 'ring-gray-300'}`}
                                    />
                                    {errors.name && touched.name ? <div className="text-red-400 text-sm py-1">{errors.name}</div> : false}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="hidden text-sm font-semibold leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter your Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="email"
                                        autoComplete="email"
                                        className={`block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 sm:text-sm sm:leading-6 ${Boolean(errors.email) && touched.email ? 'ring-red-400' : 'ring-gray-300'}`}
                                    />
                                    {errors.email && touched.email ? <div className="text-red-400 text-sm py-1">{errors.email}</div> : false}

                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="phone-number" className="hidden  text-sm font-semibold leading-6 text-gray-900">
                                    Phone number
                                </label>
                                <div className="relative mt-2.5">
                                    {/* <div className="absolute inset-y-0 left-0 flex items-center">
                                        <label htmlFor="country" className="sr-only">
                                            Country
                                        </label>
                                        <select
                                            id="country"
                                            name="country"
                                            className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-indigo-600 sm:text-sm"
                                        >
                                            <option>US</option>
                                            <option>CA</option>
                                            <option>EU</option>
                                        </select>
                                    </div> */}
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone-number"
                                        autoComplete="tel"
                                        value={values.phone}
                                        placeholder="Enter your phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={`block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 sm:text-sm sm:leading-6 ${Boolean(errors.phone) && touched.phone ? 'ring-red-400' : 'ring-gray-300'}`}
                                    />
                                    {errors.phone && touched.phone ? <div className="text-red-400 text-sm py-1">{errors.phone}</div> : false}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="hidden  text-sm font-semibold leading-6 text-gray-900">
                                    Message
                                </label>
                                <div className="mt-1">
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows={2}
                                        placeholder="Enter your Message"
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={`block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 sm:text-sm sm:leading-6 ${Boolean(errors.message) && touched.message ? 'ring-red-400' : 'ring-gray-300'}`}
                                    />
                                    {errors.message && touched.message ? <div className="text-red-400 text-sm py-1">{errors.message}</div> : false}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <button type='submit' disabled={isSubmitting} className="rounded-md px-3.5 py-2 m-1 overflow-hidden relative group cursor-pointer border-2 border-gray-200 font-medium text-primary">
                                <span className="absolute w-60 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-gray-200 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                <span className="relative text-primary/60 transition duration-300 group-hover:text-primary ease">Let&apos;s talk</span>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ContactUs
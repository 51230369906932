import { Suspense } from 'react';

// project import
// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
(
    <Suspense fallback={'Loader'}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
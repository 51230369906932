
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BackToTop from '../../components/BackToTop'
import createContact from '../../Api/NewLetter'

function Footer() {
    const [status, setStatus] = useState(null)
    const handlerSubmit = (e) => {
        e.preventDefault()
        try {
            createContact({ email: e.target.value })
            setStatus({ message: 'Successfully send message', status: 200 })
        } catch (error) {
            console.log("error", error)
            setStatus({ message: 'Something want wrong', status: 400 })
        }
        setTimeout(() => {
            setStatus(null)
            document.getElementById("Form").reset();
        }, 5000);
    }
    return (
        <>
            <footer className="bg-primary mt-10" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">Footer</h2>
                <div className="mx-auto max-w-7xl pt-12 px-4 sm:px-6 lg:pt-16 lg:px-8">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="mt-8 xl:mt-0">
                            <div className='logo brightness-[400] m-auto'>
                                <Link to='/'>
                                    <img src={'/image/logo/whitelogo.png'} alt="logo" height={300} width={300} />
                                </Link>
                            </div>
                        </div>
                        <div className="">
                            <div className="md:grid md:gap-8">
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-base font-medium text-white/60">Company</h3>
                                    <ul className="mt-4 text-white flex flex-wrap gap-y-5">
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/' >
                                                Home
                                            </Link>
                                        </li>
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/our-history' >
                                                Who We Are
                                            </Link>
                                        </li>
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/our-culture'>
                                                Culture and Values
                                            </Link>
                                        </li>
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/why-us' >
                                                Why Choose Us
                                            </Link>
                                        </li>
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/services' as='/services' >
                                                Services
                                            </Link>
                                        </li>
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/our-team' as='/our-team' >
                                                Our Team
                                            </Link>
                                        </li>
                                        <li className='flex-1/5'>
                                            <Link className={`inline-flex items-center cursor-pointer px-1 text-sm font-medium`} to='/contact' as='/contact' >
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 xl:mt-0">
                            <h3 className="text-base font-medium text-white/60">Subscribe to our newsletter</h3>
                            <p className="mt-4 text-base text-white">The latest news, articles, and resources, sent to your inbox weekly.</p>
                            {
                                status &&
                                <div className="text-center py-4 lg:px-4">
                                    <div className={`p-2  ${status.status !== 200 ? 'bg-red-400' : 'bg-lime-400 '} items-center text-white leading-none lg:rounded-full flex lg:inline-flex`} role="alert">
                                        <span className="font-semibold mr-2 text-left flex-auto">{status.message}</span>
                                    </div>
                                </div>
                            }
                            <form id="Form" onSubmit={(e) => handlerSubmit(e)} className="mt-4 sm:flex sm:max-w-md">
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input type="email" name="email-address" id="email-address" required className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-transparent py-2 px-4 text-base text-white placeholder-gray-500 shadow-sm focus:placeholder-gray-400 focus:outline-none" placeholder="Enter your email" />
                                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                    <button type='submit' className="rounded-md px-3.5 py-2 m-1 overflow-hidden relative group cursor-pointer border-2 border-gray-200 font-medium text-white">
                                        <span className="absolute w-60 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-gray-200 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                        <span className="relative text-white transition duration-300 group-hover:text-primary ease">Subscribe</span>
                                    </button>
                                </div>
                            </form>
                            <div className="flex social-icon flex-wrap mt-5 gap-2">
                                <ul>
                                    <li>
                                        <Link to="https://www.facebook.com/people/Accountix365/100089728676155/?is_tour_completed=true" target='_blank' >
                                            <i className="fab fa-facebook-f icon">
                                                <svg width="800" height="800" viewBox="0 0 24 24" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 icon flat-color"><path d="M14 6h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-3a5 5 0 0 0-5 5v3H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2v7a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-7h2.22a1 1 0 0 0 1-.76l.5-2a1 1 0 0 0-1-1.24H13V7a1 1 0 0 1 1-1Z" /></svg>
                                            </i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://twitter.com/Accountix365" target='_blank'>
                                            <i className="fab fa-twitter icon">
                                                <svg width="800" height="800" viewBox="-2 -4 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="h-6 w-6 jam jam-twitter"><path d="M20 1.907a8.292 8.292 0 0 1-2.356.637A4.07 4.07 0 0 0 19.448.31a8.349 8.349 0 0 1-2.607.98A4.12 4.12 0 0 0 13.846.015c-2.266 0-4.103 1.81-4.103 4.04 0 .316.036.625.106.92A11.708 11.708 0 0 1 1.393.754a3.964 3.964 0 0 0-.554 2.03 4.02 4.02 0 0 0 1.824 3.363A4.151 4.151 0 0 1 .805 5.64v.05c0 1.958 1.415 3.591 3.29 3.963a4.216 4.216 0 0 1-1.08.141c-.265 0-.522-.025-.773-.075a4.098 4.098 0 0 0 3.832 2.807 8.312 8.312 0 0 1-5.095 1.727c-.332 0-.658-.02-.979-.056a11.727 11.727 0 0 0 6.289 1.818c7.547 0 11.673-6.157 11.673-11.496l-.014-.523A8.126 8.126 0 0 0 20 1.907z" /></svg>
                                            </i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/company/accountix365/" target='_blank'>
                                            <i className="fab fa-linkedin-in icon">
                                                <svg width="800" height="800" viewBox="-2 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="h-6 w-6 jam jam-linkedin"><path d="M19.959 11.719v7.379h-4.278v-6.885c0-1.73-.619-2.91-2.167-2.91-1.182 0-1.886.796-2.195 1.565-.113.275-.142.658-.142 1.043v7.187h-4.28s.058-11.66 0-12.869h4.28v1.824l-.028.042h.028v-.042c.568-.875 1.583-2.126 3.856-2.126 2.815 0 4.926 1.84 4.926 5.792zM2.421.026C.958.026 0 .986 0 2.249c0 1.235.93 2.224 2.365 2.224h.028c1.493 0 2.42-.989 2.42-2.224C4.787.986 3.887.026 2.422.026zM.254 19.098h4.278V6.229H.254v12.869z" /></svg>
                                            </i></Link></li>
                                    <li>
                                        <Link to="https://www.instagram.com/accountix365/" target='_blank'>
                                            <i className="fab fa-google-plus-g icon">
                                                <svg width="800" height="800" viewBox="-2 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="h-6 w-6 jam jam-instagram"><path d="M14.017 0h-8.07A5.954 5.954 0 0 0 0 5.948v8.07a5.954 5.954 0 0 0 5.948 5.947h8.07a5.954 5.954 0 0 0 5.947-5.948v-8.07A5.954 5.954 0 0 0 14.017 0zm3.94 14.017a3.94 3.94 0 0 1-3.94 3.94h-8.07a3.94 3.94 0 0 1-3.939-3.94v-8.07a3.94 3.94 0 0 1 3.94-3.939h8.07a3.94 3.94 0 0 1 3.939 3.94v8.07z" /><path d="M9.982 4.819A5.17 5.17 0 0 0 4.82 9.982a5.17 5.17 0 0 0 5.163 5.164 5.17 5.17 0 0 0 5.164-5.164A5.17 5.17 0 0 0 9.982 4.82zm0 8.319a3.155 3.155 0 1 1 0-6.31 3.155 3.155 0 0 1 0 6.31z" /><circle cx="15.156" cy="4.858" r="1.237" /></svg>
                                            </i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 border-t border-gray-200 py-4 md:flex md:items-center md:justify-center">
                        <p className="text-base text-gray-400">&copy; 2023 Accountix365, Inc. All rights reserved.</p>
                    </div>
                </div>
            </footer>
            <BackToTop />
        </>

    )
}

export default Footer
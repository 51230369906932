import { useScroll, motion, useAnimationControls } from 'framer-motion'
import { useEffect, useState } from 'react';
import { UpArrow, Send } from '../asset/svg';
import ContactUs from './landing/ContactUs';
import Popup from './shared/popup';
import NewLetterForm from './shared/NewletterForm';

const ScrollToTopContainerVariants = {
    hide: { opacity: 0, },
    show: { opacity: 1, },
};

export default function ScrollToTopButton() {
    const { scrollYProgress } = useScroll();
    const controls = useAnimationControls();
    const [open, setOpen] = useState(false)
    const [modelOpen, setModelOpen] = useState(false)

    useEffect(() => {
        setModelOpen(true)
    }, [])


    useEffect(() => {
        return scrollYProgress.on('change', (latestValue) => {
            if (latestValue > 0.1) {
                controls.start('show');
            } else {
                controls.start('hide');
            }
        });
    }, [scrollYProgress, controls]);

    const isBrowser = () => typeof window !== 'undefined'; //The approach recommended by Next.js

    function scrollToTop() {
        if (!isBrowser()) return;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div>
            <motion.button
                className="fixed bottom-28 right-0 p-10 z-50"
                variants={ScrollToTopContainerVariants}
                initial="hide"
                animate={controls}
                onClick={scrollToTop}>
                <UpArrow className="h-8 w-8" />
            </motion.button>

            <div className='fixed right-10 bottom-10'>
                <div className="relative inline-block text-left">
                    <div className={`absolute bottom-0 z-50 md:bottom-0 right-5 md:right-[4.6rem] w-72 md:w-96 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${open ? 'flex' : 'hidden'}`} role="menu">
                        <div className="w-full" role="none">
                            <div className=" p-5 pb-0 flex justify-between w-full items-center z-50 rounded-full">
                                <h2>Get in touch</h2>
                                <button type="button" onClick={() => setOpen(false)} className="rounded-full bg-gray-300 border-2 border-white shadow-2xl shadow-gray-500/50  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"><span className="sr-only">Close</span><svg className="h-6 w-6 p-[1px]" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                                </button>
                            </div>
                            <ContactUs hide={true} />
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setOpen(true)} type="button" className=" bg-slate-100 z-30 w-16 h-16 rounded-full drop-shadow-lg flex justify-center items-center text-white p-3 overflow-hidden text-4xl hover:bg-slate-300 hover:drop-shadow-2xl duration-300" id="menu-button" aria-expanded="true" aria-haspopup="true">
                            <Send />
                        </button>
                    </div>
                </div>
            </div>
            <Popup open={modelOpen} OnClose={() => setModelOpen(false)}>
                <h2 className="text-xl font-bold tracking-tight text-center sm:text-2xl">Subscribe Now</h2>
                <div className="grid sm:grid-cols-2 h-96 sm:gap-x-8 sm:gap-y-16">
                    <div className='relative'>
                        <img src="/image/new-letter.png" alt='new-letter' className='object-contain' />
                    </div>
                    <div className="w-full flex flex-col justify-center">
                        <h3 className='font-semibold' >Great thing comes to those who subscribe!</h3>
                        <NewLetterForm />
                    </div>
                </div>
            </Popup>
        </div>
    );
}
import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.692 11.897 1.41.47c.932.31 1.397.466 1.731.8.334.334.49.8.8 1.73l.47 1.41c.784 2.354 1.176 3.53 1.897 3.53.72 0 1.113-1.176 1.897-3.53l2.838-8.512c.552-1.656.828-2.484.391-2.921-.437-.437-1.265-.161-2.92.39l-8.515 2.84C5.34 8.887 4.162 9.279 4.162 10c0 .72 1.177 1.113 3.53 1.897Z"
      fill="183c64"
    />
    <path
      d="m7.692 11.897 1.41.47c.932.31 1.397.466 1.731.8.334.334.49.8.8 1.73l.47 1.41c.784 2.354 1.176 3.53 1.897 3.53.72 0 1.113-1.176 1.897-3.53l2.838-8.512c.552-1.656.828-2.484.391-2.921-.437-.437-1.265-.161-2.92.39l-8.515 2.84C5.34 8.887 4.162 9.279 4.162 10c0 .72 1.177 1.113 3.53 1.897Z"
      fill="#183c64"
    />
    <path
      d="m9.526 13.842-2.062-.687a1 1 0 0 0-.87.116l-1.09.726a.8.8 0 0 0 .25 1.442l1.955.488a.5.5 0 0 1 .364.364l.488 1.955a.8.8 0 0 0 1.442.25l.726-1.09a1 1 0 0 0 .116-.87l-.687-2.062a1 1 0 0 0-.632-.632Z"
      fill="#181b31"
    />
  </svg>
);
export default SVGComponent;

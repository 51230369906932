import { Link, NavLink } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react'

function Header() {
    const [open, setOpen] = useState(false)
    const [aboutOpen, setAboutOpen] = useState(false)
    const [mobileAboutOpen, setMobileAboutOpen] = useState(false)
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setAboutOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <>
            <header className="sticky top-0 z-50 h-20 flex flex-wrap items-center justify-between shadow-md shadow-slate-900/5 transition duration-500 dark:shadow-none  bg-primary/95 backdrop-blur [@supports(backdrop-filter:blur(0))]:bg-bg-primary/75">
                <div className="mx-auto w-full">
                    <div className="">
                        <div className="container px-4 mx-auto">
                            <div className='flex items-center justify-between p-4 lg:p-0'>
                                <div className='logo lg:hidden brightness-[400] z-10'>
                                    <NavLink to='/'>
                                        <img src={'/image/logo/logo.png'} alt="logo" className="w-40" height={200} width={200} />
                                    </NavLink>
                                </div>
                                <div className="relative z-10 flex items-center lg:hidden">
                                    <button type="button" onClick={() => setOpen(true)} className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                        <span className="sr-only">Open menu</span>
                                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                        </svg>
                                        <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="hidden container px-4 mx-auto lg:flex justify-between lg:space-x-8 lg:py-2 relative z-10 " aria-label="Global">
                        <div className='logo brightness-[400]'>
                            <NavLink to='/' onClick={() => setAboutOpen(false)}>
                                <img src={'/image/logo/logo.png'} alt="logo" height={250} width={250} />
                            </NavLink>
                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                            <NavLink to='/' className={`inline-flex items-center cursor-pointer border-white-500 px-1 pt-1 text-sm hover:border-white-500 font-medium text-white`}  >Home</NavLink>
                            <div ref={wrapperRef} className={`relative flex border-white-500 cursor-pointer border-transparent hover:border-white border-white`}>
                                <button onMouseEnter={() => setAboutOpen(true)} type="button" className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" aria-expanded="false">
                                    <span className='inline-flex items-center  border-white-500 px-1 pt-1 text-sm font-medium text-white'>About us</span>
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="#fff" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                <div className={`absolute top-9 left-1/2 z-10 mt-5 w-max -translate-x-1/2 px-4  ${aboutOpen ? 'flex' : 'hidden'}`}>
                                    <div className="w-max max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                        <div className="p-4">
                                            <NavLink onClick={() => setAboutOpen(false)} className={({ isActive }) => `group cursor-pointer relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50`} to='our-history' >
                                                Who We Are
                                            </NavLink>
                                            <NavLink onClick={() => setAboutOpen(false)} className={({ isActive }) => `group cursor-pointer relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50`} to='our-culture'>
                                                Culture and Values
                                            </NavLink>
                                            <NavLink onClick={() => setAboutOpen(false)} className={({ isActive }) => `group cursor-pointer relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50`} to='why-us' >
                                                Why Choose Us
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NavLink onClick={() => setAboutOpen(false)} className={({ isActive }) => `inline-flex items-center cursor-pointer border-white-500 border-transparent hover:border-white border-b-[0.5px] px-1 pt-1 text-sm font-medium text-white ${isActive ? 'border-b-[0.5px] border-white' : ''}`} to='/services' as='/services' >
                                Services
                            </NavLink>
                            <NavLink onClick={() => setAboutOpen(false)} className={({ isActive }) => `inline-flex items-center cursor-pointer border-white-500 border-transparent hover:border-white border-b-[0.5px] px-1 pt-1 text-sm font-medium text-white  ${isActive ? 'border-b-[0.5px] border-white' : ''}`} to='/our-team' as='/our-team' >
                                Our Team
                            </NavLink>
                            <NavLink onClick={() => setAboutOpen(false)} className={({ isActive }) => `inline-flex items-center cursor-pointer border-white-500 border-transparent hover:border-white border-b-[0.5px] px-1 pt-1 text-sm font-medium text-white ${isActive ? 'border-b-[0.5px] border-white' : ''}`} to='/contact' as='/contact' >
                                Contact
                            </NavLink>
                        </div>
                    </nav >
                </div >
                <div className={`relative z-10 ${open ? 'visible' : 'hidden'}`} aria-labelledby="slide-over-title" role="dialog" aria-modal="false">
                    <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${open ? 'visible' : 'hidden'}`}></div>
                    <div className="fixed inset-0 overflow-hidden">
                        <div className={`absolute inset-0 overflow-hidden ${open ? 'visible' : 'hidden'}`}>
                            <div className="pointer-events-none h-screen fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <div className="pointer-events-auto relative w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6 flex justify-between">
                                            <img src={'/image/logo/logo.png'} alt="logo" height={200} width={200} />
                                            <button type="button" onClick={() => setOpen(false)} className="rounded-md text-gray-300 hover:text-white focus:outline-none">
                                                <span className="sr-only">Close panel</span>
                                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div className="absolute inset-0 px-4 sm:px-6">
                                                <div className="h-full border-gray-200" aria-hidden="true">
                                                    <div className="ml-6 flex flex-col">
                                                        <NavLink to="/" onClick={() => { setOpen(false) }} className={`inline-flex cursor-pointer items-center p-2 text-sm font-medium text-black hover:bg-gray-50`}>Home</NavLink>
                                                        <div className={` cursor-pointer border-b-[0.5px] border-white`}>
                                                            <button onClick={() => setMobileAboutOpen(!mobileAboutOpen)} type="button" className="flex w-full justify-between items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900  cursor-pointer p-2 hover:bg-gray-50" aria-expanded="false">
                                                                <span className='inline-flex items-center  border-white-500 px-1 pt-1 text-sm font-medium text-black'>About us</span>
                                                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="#000" aria-hidden="true">
                                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                                </svg>
                                                            </button>
                                                            <div className={`z-10 pt-2 pb-2 w-max ${mobileAboutOpen ? 'flex' : 'hidden'}`}>
                                                                <div className="w-max max-w-md flex-auto overflow-hidden text-sm leading-6">
                                                                    <div className="flex flex-col gap-5">
                                                                        <Link onClick={() => { setMobileAboutOpen(false); setOpen(false) }} className={`group cursor-pointer flex gap-x-6 rounded-lg pl-4 hover:bg-gray-50`} to='our-history' >
                                                                            Who We Are
                                                                        </Link>
                                                                        <Link onClick={() => { setMobileAboutOpen(false); setOpen(false) }} className={`group w-full h-5 cursor-pointer  flex gap-x-6 rounded-lg pl-4 hover:bg-gray-50`} to='/our-culture'>
                                                                            Culture and Values
                                                                        </Link>
                                                                        <Link onClick={() => { setMobileAboutOpen(false); setOpen(false) }} className={`group cursor-pointer  flex gap-x-6 rounded-lg pl-4 hover:bg-gray-50`} to='/why-us' >
                                                                            Why Choose Us
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <NavLink onClick={() => setOpen(false)} className={({ isActive }) => `inline-flex cursor-pointer items-center p-2 text-sm font-medium text-black hover:bg-gray-50`} to='/services'>
                                                            Services
                                                        </NavLink>
                                                        <NavLink onClick={() => setOpen(false)} className={({ isActive }) => `inline-flex cursor-pointer items-center p-2 text-sm font-medium text-black hover:bg-gray-50`} to='/our-team' >
                                                            Our Team
                                                        </NavLink>
                                                        <NavLink onClick={() => setOpen(false)} className={({ isActive }) => `inline-flex cursor-pointer items-center p-2 text-sm font-medium text-black hover:bg-gray-50`} to='/contact'>
                                                            Contact
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
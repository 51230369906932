import { lazy } from 'react';
import Layout from '../layout';
import Loadable from '../components/Loadable';

const AboutUs = Loadable(lazy(() => import('../about-us')));
const ContactUs = Loadable(lazy(() => import('../contact')));
const Home = Loadable(lazy(() => import('../Home')));
const Services = Loadable(lazy(() => import('../services')));
const OurTeam = Loadable(lazy(() => import('../our-team')));
const OurCulture = Loadable(lazy(() => import('../our-culture')));
const OurHistory = Loadable(lazy(() => import('../our-history')));
const WhyUs = Loadable(lazy(() => import('../why-us')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <Layout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: 'services',
            element: <Services />
        },
        {
            path: 'our-team',
            element: <OurTeam />
        },
        {
            path: 'our-team',
            element: <OurTeam />
        },
        {
            path: 'about-us',
            element: <AboutUs />
        },
        {
            path: 'contact',
            element: <ContactUs />
        },
        {
            path: 'our-culture',
            element: <OurCulture />
        },
        {
            path: 'our-history',
            element: <OurHistory />
        },
        {
            path: 'why-us',
            element: <WhyUs />
        },
    ]
};

export default MainRoutes;
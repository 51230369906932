import { useEffect } from "react";
import Routes from "./Routes";
import { useLocation } from "react-router";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;

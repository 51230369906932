import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={512}
    height={512}
    viewBox="0 0 32 32"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#181b31" data-original="#464646" />
    <path
      fill="#f1f5f9"
      d="M22.334 19.5 16 13.842 9.666 19.5 9 18.754l7-6.254 7 6.254z"
      data-name="Layer 2"
      data-original="#dcdcdc"
    />
  </svg>
);
export default SVGComponent;

export default async function createContact(data) {
    if (
        !(
            process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_CLIENT_EMAIL &&
            process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_PRIVATE_KEY &&
            process.env.REACT_APP_GOOGLE_SPREADSHEET_NEWLETTER
        )
    ) {
        throw new Error(
            'GOOGLE credentials must be set as env vars `GOOGLE_SERVICE_ACCOUNT_CLIENT_EMAIL` ,`GOOGLE_SERVICE_ACCOUNT_PRIVATE_KEY` and `GOOGLE_SPREADSHEET_ID_ORDER`.'
        );
    }

    const { GoogleSpreadsheet } = require('google-spreadsheet');
    const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SPREADSHEET_NEWLETTER);
    await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_CLIENT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_PRIVATE_KEY,
    });
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id]
    // append rows
    const NewRow = await sheet.addRow(data);
}